import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DeleteStatus, ItemMode, MasterDataStatus, patchFormValue, REGEX} from '../../shared/shared.service';
import moment from 'moment/moment';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {distinctUntilChanged} from 'rxjs';
import {PaymentDetail} from '../../shared/models/payment-detail.model';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-billing-item',
  templateUrl: './billing-item.component.html',
  styleUrl: './billing-item.component.scss'
})
export class BillingItemComponent implements OnInit, AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formGroup!: FormGroup;
  itemMode = ItemMode;
  mode = ItemMode.Add;
  data!: any;
  listOwner: any[] = [];
  listPaymentMethod: any[] = [];
  REGEX = REGEX;
  expand = true;
  listPayment: any[] = [];
  totalDue: number = 0;
  dataTopUp!: any;
  currentDateMax = moment().add(1, 'd').set({h: 0, m: 0, s: 0, ms: -1}).toDate();
  keywordOwner = '';
  cancelSearchOwner = false;

  get f() {
    return this.formGroup.controls;
  }

  constructor(
    private service: ProxyDynamicService,
    private modalRef: NzModalRef,
    private noti: NzNotificationService,
    private translateService: TranslateService,
    private fb: FormBuilder,
  ) {
    this.listPaymentMethod = this.nzModalData.listPaymentMethod;
    if (this.nzModalData.data) {
      this.data = JSON.parse(JSON.stringify(this.nzModalData.data));
    }
    if (this.nzModalData.dataTopUp) {
      this.dataTopUp = JSON.parse(JSON.stringify(this.nzModalData.dataTopUp));
    }
    this.mode = this.nzModalData.mode;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: [undefined],
      ticket_no: [null],
      payment_date: [moment().toDate(), Validators.required],
      payment_method: [null, Validators.required],
      owner: [null, Validators.required],
      note: [null],
      payment_detail: [null]
    })
    this.f['payment_date'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (this.mode === ItemMode.Add) {
        this.getListTopUp([]);
      }
    })
    this.f['owner'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (this.mode === ItemMode.Add) {
        this.getListTopUp([]);
      }
    })
  }

  ngAfterViewInit() {
    if (this.mode !== ItemMode.Add) {
      if (this.nzModalData.data.owner) {
        this.keywordOwner = this.nzModalData.data.owner.name
        this.getMoreDataOwner(false);
      }
      patchFormValue(this.formGroup, this.data);
      if (this.mode === ItemMode.View) {
        this.formGroup.disable({emitEvent: false});
      }
      if (this.mode === ItemMode.Edit) {
        this.f['ticket_no'].disable();
        this.f['owner'].disable({emitEvent: false});
      }
      this.f['payment_date'].setValue(moment(this.data.payment_date).utc(false).toDate(), {emitEvent: false});
      this.getListTopUpByPaymentNo(this.data.ticket_no);
      this.calculateReceivedTotal();
    } else {
      if (this.dataTopUp) {
        this.f['payment_date'].setValue(moment().toDate(), {emitEvent: false});
        this.f['owner'].setValue(this.dataTopUp.owner_id, {emitEvent: false});
        this.f['ticket_no'].disable({emitEvent: false});
        this.f['owner'].disable({emitEvent: false});
        this.getListTopUp([{
          "field": "topup_no",
          "operator": "=",
          "value": this.dataTopUp.ticket_no
        }])
        this.calculateReceivedTotal();
      }
    }
  }

  getListTopUp(body: any[]) {
    const list: any[] = [{
      "field": "due_amount",
      "operator": ">=",
      "value": 1
    }];
    if (this.f['payment_date'].value) {
      list.push({
        "field": "deposit_date",
        "operator": "<=",
        "value": moment(this.f['payment_date'].value).format('YYYY-MM-DD')
      })
    }
    if (this.f['owner'].value) {
      list.push({
        "field": "owner_id",
        "operator": "=",
        "value": this.f['owner'].value
      })
    }
    if (body.length || list.length) {
      this.service.getListTopUpByOwnerAndDate({
        "searchFields": body.length ? body : list,
        "sortField": "topup_transaction_id",
        "sortOrder": "ASC"
      }).subscribe(res => {
        if (res.data.length) {
          this.listPayment = res.data.map((item: any) => {
            item['received_amount'] = item.due_amount;
            return item;
          });
          this.calculateReceivedTotal();
        } else {
          this.listPayment = [];
        }
      }, (error: any) => {
       this.service.handleError(error);
      });
    } else if (!this.f['payment_date'].value && !this.f['owner'].value) {
      this.listPayment = [];
    }
  }

  getListTopUpByPaymentNo(ticket_no: string) {
    this.service.getListTopUpByPaymentNo({'payment_no': ticket_no}).subscribe((res => {
      this.listPayment = res.data;
      this.calculateReceivedTotal();
    }))
  }

  editMode() {
    this.mode = ItemMode.Edit;
    this.formGroup.enable({emitEvent: false});
    this.f['payment_date'].setValue(moment(this.data.payment_date).utc(false).toDate(), {emitEvent: false});
    this.getListTopUpByPaymentNo(this.data.ticket_no);
    this.calculateReceivedTotal();
    this.f['ticket_no'].disable({emitEvent: false});
    this.f['owner'].disable({emitEvent: false});
  }

  destroyModal() {
    this.modalRef.destroy()
  }

  onChangeReceivedAmount(item: any, event: any) {
    item['received_amount'] = event;
    this.calculateReceivedTotal();
  }

  calculateReceivedTotal() {
    this.totalDue = this.listPayment.reduce((a: number, b: PaymentDetail) => {
      return a + (Number(b.received_amount) || 0);
    }, 0)
  }

  onSave() {
    const validReceived = this.validateReceivedAmount()
    if (this.formGroup.valid && validReceived) {
      const body = this.formGroup.getRawValue();
      body.payment_date = moment(body.payment_date).format('YYYY-MM-DD');
      if (this.mode === ItemMode.Add) {
        body.id = undefined;
        body.ticket_no = undefined;
        body.payment_detail = this.listPayment.filter(r => r.received_amount).map((item: any) => {
          let obj = {
            topup_transaction: item.topup_transaction_id ? item.topup_transaction_id : undefined,
            received_amount: item.received_amount,
            id: undefined
          }
          return obj;
        });
        this.service.createItem('payment/create', body).subscribe(() => {
          this.modalRef.destroy(true);
        }, (error: any) => {
          this.modalRef.destroy(false);
         this.service.handleError(error)
        });
      } else if (this.mode === ItemMode.Edit) {
        body.payment_detail = this.listPayment.map((item: any) => {
          let obj = {
            topup_transaction: item.topup_transaction_id ? item.topup_transaction_id : undefined,
            received_amount: item.received_amount,
            id: item.payment_detail_id
          }
          return obj;
        });
        this.service.updateItem('payment', body.id, body).subscribe(() => {
          this.modalRef.destroy(true);
        }, (error: any) => {
          this.modalRef.destroy(false);
         this.service.handleError(error)
        });
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  validateReceivedAmount() {
    if (this.listPayment.filter(r => r.received_amount).length !== 0) {
      return true;
    } else {
      this.noti.create('error', '', this.translateService.instant('error.billing-required'));
      return false;
    }
  }

  print() {
    this.modalRef.destroy({print: this.data});
  }

  getMoreDataOwner(event: any) {
    if (!this.cancelSearchOwner) {
      const query = !!this.keywordOwner ? {
        '_or': [{'name': {'_icontains': this.keywordOwner}}, {'code': {'_icontains': this.keywordOwner}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('owner', {
        filter: query,
        limit: 100,
        page: !event ? 0 : Math.round(this.listOwner.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordOwner) {
          this.listOwner = res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listOwner = this.listOwner.concat(res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          }));
        }
        this.cancelSearchOwner = this.listOwner.length === Number(res.total);
      })
    }
  }

  filterDataOwner(event: any) {
    this.keywordOwner = event;
    this.cancelSearchOwner = false;
    if (event) {
      this.getMoreDataOwner(false);
    } else {
      this.listOwner = []
    }
  }
}
